<template>
  <div
    class="shadow-lg hover:shadow-2xl rounded-2xl w-64 p-4 py-10 bg-white text-teal hover:text-orange transition-all duration-500 cursor-pointer m-4"
  >
    <div class="flex flex-col items-center justify-center">
      <div class="w-24 h-24 shadow rounded-full relative">
        <span
          class="material-icons text-5xl absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2"
        >
          {{ icon }}
        </span>
      </div>
      <p class="text-center font-medium mb-4 mt-4 text-grey text-md">
        {{ title }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
