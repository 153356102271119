<template>
  <div class="flex flex-nowrap">
    <div class="flex justify-center py-2 flex-1">
      <h1
        class="text-orange px-20 align-middle justify-center text-2xl font-bold p-4"
      >
        Services
      </h1>
    </div>
  </div>
  <div class="flex flex-wrap justify-center items-center">
    <div class="flex flex-wrap justify-center items-center w-10/12 pb-14">
      <router-link :to="{ name: 'hq-humanResources' }">
        <dashboard-item-card :icon="'groups'" :title="'Human Resources'">
        </dashboard-item-card>
      </router-link>
      <dashboard-item-card
        :icon="'account_balance'"
        :title="'Accounting & Finance'"
      >
      </dashboard-item-card>
      <dashboard-item-card :icon="'assignment_turned_in'" :title="'Compliance'">
      </dashboard-item-card>
      <dashboard-item-card :icon="'fax'" :title="'IT & Telephone'">
      </dashboard-item-card>
      <router-link :to="{ name: 'hq-monitoringOfPracticeFunctionsDashboard' }">
        <dashboard-item-card
          :icon="'align_horizontal_left'"
          :title="'Monitoring Of Practice Function'"
        >
        </dashboard-item-card>
      </router-link>
      <dashboard-item-card
        :icon="'real_estate_agent'"
        :title="'Premises & Estates'"
      >
      </dashboard-item-card>
      <dashboard-item-card
        :icon="'health_and_safety'"
        :title="'Centralised Clinical Activity'"
      >
      </dashboard-item-card>
    </div>
  </div>
</template>
<script>
import dashboardItemCard from "@/components/ui/baseComponents/dashboardItemCard.vue";
export default {
  components: { dashboardItemCard },
  data() {
    return {};
  },
  created() {
    this.fetchPractices();
  },
  methods: {
    async fetchPractices() {
      try {
        await this.$store.dispatch("HQnewHire/fetchPractices");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
  },
};
</script>
